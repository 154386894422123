var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "products" },
    [
      _c("CategoryTitle", {
        attrs: { category: _vm.category, showDescription: true }
      }),
      _c(
        "v-row",
        _vm._l(_vm.newsList, function(news) {
          return _c(
            "v-col",
            {
              key: news.id,
              attrs: { cols: "12", md: "6", xl: "4" },
              on: {
                click: function($event) {
                  return _vm.openNews(news)
                }
              }
            },
            [
              _c("news-card", {
                key: news.newsId,
                attrs: { news: news, "show-opened": true, "show-read": false },
                on: {
                  read: function($event) {
                    return _vm.markAsRead(news.newsId)
                  }
                }
              })
            ],
            1
          )
        }),
        1
      ),
      _vm.pager && _vm.pager.totPages > 1 && !_vm.$vuetify.breakpoint.xs
        ? _c("v-pagination", {
            attrs: {
              value: _vm.page,
              page: _vm.pager.selPage,
              length: _vm.pager.totPages ? _vm.pager.totPages : 0,
              totalVisible: 6
            },
            on: {
              next: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.handlePageFilter.apply(null, arguments)
              },
              prev: _vm.handlePageFilter,
              input: _vm.handlePageFilter
            }
          })
        : _vm._e(),
      _vm.openedNews != null
        ? _c("NewsAlert", { attrs: { news: _vm.openedNews } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }