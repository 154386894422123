var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      on: {
        click: function($event) {
          return _vm.handleLink(_vm.news)
        }
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    _vm._l(_vm.newsList, function(news) {
      return _c("news-card", {
        key: news.newsId,
        attrs: { news: news, "show-opened": false, "show-read": true },
        on: {
          read: function($event) {
            return _vm.markAsRead(news.newsId)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }