var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "news-card", attrs: { elevation: "2" } },
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.news.title))]),
      _c("v-card-text", [
        _c("div", {
          staticClass: "news-text",
          domProps: { innerHTML: _vm._s(_vm.news.descr) },
          on: { click: _vm.handleLink }
        })
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm.news.navTo
            ? _c(
                "a",
                {
                  attrs: { link: "", href: _vm.news.navTo },
                  on: { click: _vm.handleLink }
                },
                [_vm._v(" continua a leggere ")]
              )
            : _vm._e(),
          _vm.showRead
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary", outlined: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.$emit("read")
                    }
                  }
                },
                [_vm._v(" Ok ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }